var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buscador"},[_c('q-dialog',{staticStyle:{"border-radius":"25px !important"},model:{value:(_vm.$store.state.modalBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "modalBusqueda", $$v)},expression:"$store.state.modalBusqueda"}},[_c('div',{staticClass:"col-12 row justify-center bg-none",staticStyle:{"border-radius":"25px !important","height":"60vh !important","overflow":"hidden"}},[_c('div',{staticClass:"q-pb-xl q-pa-sm row justify-center",style:('background: rgba(0, 0, 0, 0.287) !important;border-radius: 25px !important;' +
            (_vm.$q.screen.xs ? '' : ' width: 100vw;'))},[_c('div',{staticClass:"row justify-end col-12 items-center "},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"q-mb-sm text-white",attrs:{"icon":"highlight_off","flat":"","round":"","dense":"","size":"xl"},on:{"click":function($event){return _vm.cerrar()}}})],1),_c('q-card-section',{class:'q-px-none ' + (_vm.$q.screen.xs ? 'col-12' : 'col-8')},[_c('div',{staticClass:"row justify-center bg-accentt"},[_c('div',{staticClass:"row justify-center text-center items-center col-12"},[_c('q-input',{ref:"search",staticClass:"full-width q-pa-none q-my-sm q-px-none",attrs:{"placeholder":"¿Qué necesitas encontrar?","rounded":"","item-aligned":"","outlined":"","maxlength":"80","color":"white","clearable":"","clear-icon":"close","bg-color":"white"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer q-mr-xs",attrs:{"name":"search","color":"secondary","size":"md"},on:{"click":function($event){return _vm.buscar(_vm.$store.state.textBusqueda)}}}),_c('q-separator',{attrs:{"vertical":"","inset":""}})]},proxy:true}]),model:{value:(_vm.$store.state.textBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "textBusqueda", $$v)},expression:"$store.state.textBusqueda"}})],1)])]),_c('q-card-section',{class:'row justify-center text-accent q-pa-none palabras_largas ' +
              (_vm.$q.screen.xs ? 'col-12' : 'col-8'),staticStyle:{"border-radius":"25px !important","height":"30vh","overflow-y":"auto","overflow-x":"hidden"}},[_c('div',{staticClass:"row col-12",staticStyle:{"border-radius":"25px !important"}},[(
                _vm.busquedaData &&
                  _vm.busquedaData.dataPaginas &&
                  _vm.busquedaData.dataPaginas.length == 0 &&
                  _vm.busquedaData.dataNoticias &&
                  _vm.busquedaData.dataNoticias.length == 0 &&
                  _vm.busquedaData.dataDocumentos &&
                  _vm.busquedaData.dataDocumentos.length == 0 &&
                  _vm.busquedaData.dataGalerias &&
                  _vm.busquedaData.dataGalerias.length == 0 &&
                  _vm.links &&
                  _vm.links.length == 0
              )?_c('q-card-section',{staticClass:"bg-white row flex-center",staticStyle:{"border-radius":"25px !important","height":"150px","width":"100%"}},[_c('p',{staticClass:"text-bold text-secondary col-12"},[_vm._v(" La búsqueda de \""+_vm._s(_vm.$store.state.textBusqueda)+"\" no obtuvo ningún resultado ")])]):_vm._e(),(
                _vm.busquedaData &&
                  _vm.busquedaData.dataNoticias &&
                  _vm.busquedaData.dataNoticias.length > 0
              )?_c('q-card-section',{class:_vm.busquedaData.dataNoticias.length > 0
                  ? 'col-12 bg-grey-4'
                  : 'col-12 bg-white'},[_c('div',{staticClass:"col-12 q-px-sm"},[_c('p',{staticClass:"text-bold text-secondary q-my-md",staticStyle:{"font-size":"20px"}},[_vm._v(" Noticias ")]),_vm._l((_vm.busquedaData.dataNoticias),function(noticia,index){return _c('div',{key:index,staticClass:"row col-12 q-my-sm"},[_c('div',{staticClass:"col-12 cursor-pointer texto_truncado",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.irNoticia(noticia.nombre_web)}}},[_vm._v(" "+_vm._s(noticia.titulo)+" ")])])})],2)]):_vm._e(),(
                _vm.busquedaData &&
                  _vm.busquedaData.dataDocumentos &&
                  _vm.busquedaData.dataDocumentos.length > 0
              )?_c('q-card-section',{class:'col-12 ' +
                  (_vm.busquedaData.dataNoticias.length == 0
                    ? 'bg-grey-4'
                    : 'bg-white')},[_c('div',{staticClass:"col-12 q-px-sm"},[_c('p',{staticClass:"text-bold text-secondary q-py-md",staticStyle:{"font-size":"20px"}},[_vm._v(" Documentos ")]),_vm._l((_vm.busquedaData.dataDocumentos),function(actividad,index){return _c('div',{key:index,staticClass:"row col-12 q-mb-sm"},[_c('div',{staticClass:"cursor-pointer palabras_largas texto_truncado",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.descargarDocumento(actividad.archivo)}}},[_vm._v(" "+_vm._s(_vm.$q.screen.xs ? actividad.nombre.substring(0, 40) : actividad.nombre)+" ")])])})],2)]):_vm._e(),(_vm.busquedaData && _vm.links && _vm.links.length > 0)?_c('q-card-section',{class:(_vm.busquedaData.dataNoticias.length == 0 &&
                  _vm.busquedaData.dataDocumentos.length == 0) ||
                (_vm.busquedaData.dataNoticias.length == 0 &&
                  _vm.busquedaData.dataDocumentos.length < 1) ||
                (_vm.busquedaData.dataNoticias.length > 0 &&
                  _vm.busquedaData.dataDocumentos.length > 0)
                  ? 'col-12 bg-grey-4'
                  : 'col-12 bg-white'},[_c('div',{staticClass:"row text-busqueda"},[_c('div',{staticClass:"col-12 q-px-sm"},[_c('p',{staticClass:"text-bold text-secondary q-my-md",staticStyle:{"font-size":"20px"}},[_vm._v(" Links de interés ")]),_vm._l((_vm.links),function(link,index){return _c('div',{key:index,staticClass:"row col-12 q-mt-sm"},[(link.tipo == 1)?_c('div',{staticClass:"col-12 cursor-pointer texto_truncado",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.irPagina(link.nombre_web)}}},[_vm._v(" "+_vm._s(link.nombre)+" ")]):_vm._e(),(link.tipo == 0)?_c('div',{staticClass:"col-12 cursor-pointer texto_truncado",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.toLink(link.url)}}},[_vm._v(" "+_vm._s(link.nombre)+" ")]):_vm._e()])})],2)])]):_vm._e(),(
                _vm.busquedaData &&
                  _vm.busquedaData.dataGalerias &&
                  _vm.busquedaData.dataGalerias.length > 0
              )?_c('q-card-section',{class:(_vm.busquedaData.dataNoticias.length > 0 &&
                  _vm.busquedaData.dataDocumentos.length > 0 &&
                  (_vm.busquedaData.dataLinks.length > 0 ||
                    _vm.busquedaData.dataPaginas.length > 0)) ||
                (_vm.busquedaData.dataNoticias.length == 0 &&
                  _vm.busquedaData.dataDocumentos.length == 0 &&
                  (_vm.busquedaData.dataLinks.length > 0 ||
                    _vm.busquedaData.dataPaginas.length > 0))
                  ? 'col-12 bg-white'
                  : 'col-12 bg-grey-4'},[_c('div',{staticClass:"row text-busqueda"},[_c('div',{staticClass:"col-12 q-px-sm"},[_c('p',{staticClass:"text-bold text-secondary",staticStyle:{"font-size":"20px"}},[_vm._v(" Galerías ")])]),_vm._l((_vm.busquedaData.dataGalerias),function(item){return _c('div',{key:item.id,staticClass:"\n                    row\n                    col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3\n                    bg-img\n                    q-ma-sm\n                    cursor-pointer\n                    justify-center\n                  ",on:{"click":function($event){return _vm.irGaleria(item.nombre_web)}}},[_c('div',{staticClass:"col-12 q-pa-md cursor-pointer",on:{"click":function($event){return _vm.irGaleria(item.nombre_web)}}},[_c('q-img',{staticStyle:{"width":"100% !important","height":"auto !important","border-style":"!important"},attrs:{"src":item.imagen_preview}})],1),_c('div',{staticClass:"\n                      col-11\n                      row\n                      justify-center\n                      q-my-md\n                      text-center\n                      cursor-pointer\n                    ",on:{"click":function($event){return _vm.irGaleria(item.nombre_web)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.nombre,48, "..."))+" ")])])})],2)]):_vm._e()],1)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }